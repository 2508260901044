import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-card-contacto',
    imports: [],
    templateUrl: './card-contacto.component.html',
    styleUrl: './card-contacto.component.css'
})
export class CardContactoComponent {
  @Input() title: string = '';
}
