import { Component, ElementRef, AfterViewInit, ViewChild, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SwiperContainer } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-revolution-slider',
  standalone: true,
  templateUrl: './revolution-slider.component.html',
  styleUrl: './revolution-slider.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})


export class RevolutionSliderComponent implements AfterViewInit {

  @ViewChild('swiper') swiperRef!: ElementRef<SwiperContainer>;

    slides = [
      {
        img: 'assets/img/slider/cabecera1',
        title: 'Ingeniería y Proyectos',
        subtitle: 'Soluciones innovadoras'
      },
      {
        img: 'assets/img/slider/cabecera2',
        title: 'Aerotermia',
        subtitle: 'Mantenimiento e instalación en viviendas'
      },
      {
        img: 'assets/img/slider/cabecera3',
        title: 'Calderas individuales ',
        subtitle: 'Mantenimiento e instalación'
      },
      {
        img: 'assets/img/slider/cabecera4',
        title: 'Instalaciones industriales',
        subtitle: 'Mantenimiento'
      },
      {
        img: 'assets/img/slider/cabecera5',
        title: 'Instalaciones de gas natural',
        subtitle: 'Revisión periódica'
      },
      {
        img: 'assets/img/slider/cabecera6',
        title: 'Receptoras de gas',
        subtitle: 'Revisión y mantenimiento'
      },
      {
        img: 'assets/img/slider/cabecera7',
        title: 'Calderas individuales',
        subtitle: 'Sustitución'
      }
    ];


    ngAfterViewInit(): void {
      const swiperParams: SwiperOptions = {
        slidesPerView: 1,
        grabCursor: true,
        autoplay: { delay: 4000, disableOnInteraction: true },
        speed: 1000,
        loop: true,
        effect: "creative",
        creativeEffect: {
          prev: {
            shadow: true,
            translate: [0, "-20%", -1],
          },
          next: {
            translate: [0, "100%", 0],
          },
        },
        navigation: {
          prevEl: '.left .material-symbols-outlined',
          nextEl: '.right .material-symbols-outlined'
        } ,
        on: {
          slideChangeTransitionEnd: () => {            
            this.activeIndex = (this.swiperRef.nativeElement as any).swiper.realIndex;
            console.log(this.activeIndex);
          }
        }    
      };
  

      setTimeout(() => {
        Object.assign(this.swiperRef.nativeElement, swiperParams);
        (this.swiperRef.nativeElement as any).initialize();
       
     }, 0);
    }

    activeIndex = 0;

    onSlideChange() {
      
      this.activeIndex = (this.swiperRef.nativeElement as any).realIndex;
    }
}




