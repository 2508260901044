// import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { CookiesService } from '../../services/cookies.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-politica-de-cookies',
    imports: [],
    templateUrl: './politica-de-cookies.component.html',
    styleUrl: './politica-de-cookies.component.css'
})
export class PoliticaDeCookiesComponent implements OnInit {

  constructor(private readonly cookiesServiceManual : CookiesService, private readonly meta: Meta, private readonly title: Title) {}

  ngOnInit(): void {
    this.title.setTitle('Política de Cookies - Centralgas');
    this.meta.addTag({ name: 'description', content: 'Conoce la política de cookies de Centralgas de grupo Sunflower Energías. Descubre cómo utilizamos cookies para mejorar la experiencia de usuario, personalizar contenido y analizar el uso de nuestro sitio web' });
    const chkPersonalizacion = document.getElementById("chkCPState") as HTMLInputElement;
    const chkAnalitica = document.getElementById("chkCAState") as HTMLInputElement;
  

    if (chkPersonalizacion) {
      chkPersonalizacion.checked = this.cookiesServiceManual.personalizacionAceptada();
    }
  
    if (chkAnalitica) {
      chkAnalitica.checked = this.cookiesServiceManual.analiticaAceptada();
      console.log('Cookies Analíticas Aceptadas al Cargar:', chkAnalitica.checked);
    }


    // Si no se aceptaron las cookies personalizadas, mostramos la barra de aceptación
    if (!this.cookiesServiceManual.personalizacionAceptada()) {
      this.cookiesServiceManual.mostrarBarraAceptacion(true);
    }

    // Si ya se aceptaron las cookies analíticas, activa la funcionalidad de Google Analytics
    if (this.cookiesServiceManual.analiticaAceptada()) {
     
      if (chkAnalitica) {
        chkAnalitica.checked = this.cookiesServiceManual.analiticaAceptada();
        console.log('Cookies Analíticas Aceptadas después de inicializar GA:', chkAnalitica.checked);
      }
    }
  }

  onAnaliticaStateChange(event: Event): void {
    this.cookiesServiceManual.analiticaStateChange(event);
  }


  onPersonalizacionStateChange(event: Event): void {
    this.cookiesServiceManual.personalizacionStateChange(event);
  }
  

}
