import { Component } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
// import { QuienesSomosComponent } from './components/quienes-somos/quienes-somos.component';
// import { SocioColaboradorComponent } from './components/socio-colaborador/socio-colaborador.component';
// import { RevolutionSliderComponent } from './components/revolution-slider/revolution-slider.component';
// import { TrabajosComponent } from './components/trabajos/trabajos.component';
// import { ServiciosComponent } from './components/servicios/servicios.component';
// import { TrabajosCardSimpleComponent } from './components/trabajos-card-simple/trabajos-card-simple.component';
// import { InstalacionesDomesticasComponent } from './components/instalaciones-domesticas/instalaciones-domesticas.component';
// import { ContactoComponent } from './components/contacto/contacto.component';
// import { ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './components/footer/footer.component';
import {CookieService} from 'ngx-cookie-service';

@Component({
    selector: 'app-root',
    imports: [
        RouterOutlet,
        HeaderComponent,
        FooterComponent
    ],
    providers: [CookieService],
    templateUrl: './app.component.html',
    styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'CentralGas';
}
