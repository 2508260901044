<section>
    <div class="rev_slider_wrapper">
        <div class="rev_slider" id="slider4" data-version="5.0">
          <swiper-container #swiper class="mySwiper" init="false">
            @for (slide of slides; track slide.title; let i = $index) {
              <swiper-slide>
                <div class="caption">
                  @if(activeIndex === i) {
                    <div [class.subtitle]="activeIndex === i">{{ slide.subtitle }}</div>
                    <div [class.title]="activeIndex === i">{{ slide.title }}</div>
                  }                 
                </div>
              <picture>
                <source srcset="{{slide.img}}.webp" type="image/webp" [attr.alt]="slide.title" >
                <source srcset="{{slide.img}}.jpeg" type="image/jpeg" [attr.alt]="slide.title"> 
                <img class="img-fw" loading="lazy" src="{{slide.img}}.jpg" [attr.alt]="slide.title"/>
              </picture>          
               
            </swiper-slide>
            }
          </swiper-container>
        </div>
    </div>
</section> 


