import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';


@Component({
    selector: 'app-header',
    imports: [
        CommonModule
    ],
    templateUrl: './header.component.html',
    styleUrl: './header.component.css'
})
export class HeaderComponent {
  isOpenLanguage: boolean = false;
  isMenuOpen: boolean = false;


  toggleDropdown() {
    this.isMenuOpen = !this.isMenuOpen;
  }

   toggleDropdownLanguage() {
    this.isOpenLanguage = !this.isOpenLanguage;
  }

  selectLanguage(lang: string) {
    console.log('lengua:', lang);
    this.isOpenLanguage = false; 
  }
}
